import React, { FC } from "react";
import {
  Container,
  Block,
  TextBlock,
  InfoText,
  DocsBlock,
  DocsWrapper,
  IconLinkContainer,
  StyledIllu
} from './styles'
import { useTranslation } from "react-i18next";
import { Typography, Table, Button } from "../../../../../components";
import IconLink from './link-ic.svg'
import Frame1 from './frame-1-light.svg'
import Frame1Dark from './frame-1-dark.svg'
import Frame2 from './fame-2.svg'
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

const Component:FC = () => {
  const [t] = useTranslation()
  const theme:any = useTheme()
  const navigate = useNavigate()
  return(
    <Container>
      <Block>
        <TextBlock>
          <InfoText>
            {t("LIFECYCLE_CONFIGURATION_TITLE")}
          </InfoText>
          <Button
            variant="primary"
            size="display"
            onClick={() => navigate('/object-storage/create-lifecycle-rule')}
            title={`+ ${t("CREATE_LIFECYCLE_RULE")}`}
          />
        </TextBlock>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_INFO")}
        </Typography>
        <div>
          <Table
            columns={[
              {
                title:t("VISIBILITY"),
                key:'visibility'
              },
              {
                title:t("USE_CASE"),
                key:'use_case',
              },
              {
                title:t("BUCKET_ID"),
                key:'bucket_id',
              },
              {
                title:t("SIZE"),
                key:'size'
              },
            ]}
            rows={[
              {
                visibility:'',
                use_case:'',
                bucket_id:'',
                size:'',
              }
            ]}
            hideOther
            hideCheckbox
            // paginationData={paginationData}
          />
          </div>
        </Block>
        <Block style={{gap:"10px"}}>
          <DocsWrapper>
            <DocsBlock>
              <IconLinkContainer>
                <img src={IconLink} alt='IconLink'/>
              </IconLinkContainer>
              <div>
                <InfoText>
                  {t("OPTIMIZE_PERFOMANCE_TITLE")}
                </InfoText>
                <Typography variant="h3">
                  {t("OPTIMIZE_PERFOMANCE_DESCRIPTION")}
                </Typography>
              </div>
              <StyledIllu src={theme.isDark? Frame1Dark : Frame1}/>
            </DocsBlock>
            <DocsBlock>
              <IconLinkContainer>
                <img src={IconLink} alt='IconLink'/>
              </IconLinkContainer>
              <div>
                <InfoText>
                  {t("PROTECT_DATA_TITLE")}
                </InfoText>
                <Typography variant="h3">
                  {t("PROTECT_DATA_DESCRIPTION")}
                </Typography>
              </div>
              <StyledIllu style={{top:'12px', right:'34px'}} src={Frame2}/>
            </DocsBlock>
          </DocsWrapper>
        </Block>
    </Container>
  )
}

export default Component