import React, { FC, useState } from "react";
import {
  Container,
  Block,
  TextBlock,
  InfoText,
} from './styles'
import { Button, Input, Link, RadioButtonGroup, Table, Typography } from "../../../../../components";
import { useTranslation } from "react-i18next";
import { InfoContainer } from "../../../../../components/info-block/styles";
import { useNavigate } from "react-router-dom";



const Component:FC<any> = ({bucket}) => {
  console.log('bucket',bucket);
  const [t] = useTranslation()
  const [visibility, setVisibility] = useState<any>('private')
  const navigate = useNavigate()
  return(
    <Container>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_INFO")}
        </Typography>
        <div>
          <Table
            columns={[
              {
                title:t("VISIBILITY"),
                key:'visibility'
              },
              {
                title:t("USE_CASE"),
                key:'use_case',
              },
              {
                title:t("BUCKET_ID"),
                key:'bucket_id',
              },
              {
                title:t("SIZE"),
                key:'size'
              },
            ]}
            rows={[
              {
                visibility:'',
                use_case:'',
                bucket_id:'',
                size:'',
              }
            ]}
            hideOther
            hideCheckbox
            // paginationData={paginationData}
          />
          <Table
            columns={[
              {
                title:t("NUMBER_OF_OBJECTS"),
                key:'count'
              },
              {
                title:t("BUCKET_ENDPOINT"),
                key:'endpoint',
              },
            ]}
            rows={[
              {
                count:'',
                endpoint:'',
              }
            ]}
            hideOther
            hideCheckbox
            // paginationData={paginationData}
          />
        </div>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("TAGS")}
        </Typography>
        <InfoContainer style={{display:'flex', flexDirection:'column', gap:'10px'}}>
          <Typography variant="body">
            {t("TAGS_DESC")}
          </Typography>
            <Input
              fieldName='name'
              title={''}
              placeholder=''
              // {...formikProps}
            />
        </InfoContainer>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_POLICIES")}
        </Typography>
        <TextBlock>
          <InfoText style={{display:'inline-flex', gap:'4px'}}>
            {t("BUCKET_POLICIES_INFO")}
            <span>
              <Link link='' linkText={t("BUCKET_POLICIES_INFO")}/>
            </span>
            {t("BUCKET_POLICIES_INFO")}
          </InfoText>
          <Button
            variant="primary"
            size="display"
            onClick={() => navigate('/object-storage/create-policy')}
            title={`+ ${t("CREATE_BUCKET_POLICY")}`}
          />
        </TextBlock>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_VISIBILITY")}
        </Typography>
        <InfoContainer style={{display:'flex', flexDirection:'column', gap:'12px', width:'100%', alignItems:'flex-start'}}>
          <InfoText>
            {t("VISIBILITY_INFO")}
          </InfoText>
          <RadioButtonGroup
            title={''}
            buttons={[
              { value: 'private', title: t('PRIVATE') },
              { value: 'public-read', title: t('PUBLIC') },
            ]}
            selectedButton={visibility}
            onChange={(e:any) => setVisibility(e)}
          />
        </InfoContainer>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("DELETE_BUCKET")}
        </Typography>
        <TextBlock>
          <InfoText>
            {t("DELETE_BUCKET_INFO")}
          </InfoText>
          <Button
            variant="primary"
            size="display"
            onClick={() => {}}
            title={`${t("DELETE_BUCKET")}`}
          />
        </TextBlock>
      </Block>
    </Container>
  )
}

export default Component