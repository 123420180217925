import styled from "styled-components";
import { BodyDefault } from "../../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 30px 50px 30px;
  gap:20px;
`
export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
`
export const TextBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap:20px;
`

export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`

export const DocsBlock = styled.div`
  background-color: ${({theme}) => theme.grey[300]};
  border-radius: 8px;
  padding: 20px;
  height: 161px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  gap:10px;
  align-items: flex-start;
  overflow: hidden;
  &:hover{
    cursor: pointer;
    opacity: 0.8;
  }
`

export const DocsWrapper = styled.div`
  display: flex;
  gap:30px;

`

export const IconLinkContainer = styled.div`
  border: 1px solid ${({theme}) => theme.grey[400]};
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #0000001A;
  box-sizing: border-box;
  padding: 10px;
`

export const StyledIllu = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`