import styled from "styled-components";
import { BodyDefault, HeadLine1, HeadLine3, VerySmall } from "../../../components/typography/styled";

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  width:100%;
`
export const BodyContainer = styled.div`
  display:flex;
  gap:30px;
  flex-direction: column;
  width:100%;
  box-sizing: border-box;
  padding: 0px 30px 50px 30px;

`
export const CreateContainer = styled.div`
  flex-direction: column;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
  padding: 10px 30px;

`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
`

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:16px;
  padding-top:16px;
`

export const StepContainer = styled(StepsWrapper)`
  box-sizing: border-box;
  display: flex;
  padding-top:0px;
  width: 100%;
  gap:10px;
`

export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`
export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  gap:10px;
`

export const CheckboxContainer = styled.div<{active:boolean}>`
  padding: 20px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 8px;
  gap: 6px;
  /* box-shadow: 0px 1px 2px 0px #0000001A; */
  /* border: 1px solid #E8E8E8 */
  background-color: ${({theme}) => theme.palette[10]};
  box-shadow: ${({active, theme}) => active ? '' : '0px 1px 2px rgba(0, 0, 0, 0.1)'};
  border: ${({theme, active}) => active ? `1px solid ${theme.primary[200]}` : `1px solid ${theme.palette[20]}`};
  &:hover{
    cursor: pointer;
    opacity:0.8;
  }
`

export const DopBlock = styled.div`
  display: flex;
  gap:20px;
  margin-left: 60px;
`