import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Select,
  Button,
  TopProgress,
  LoaderSpinner,
  Typography,
  RadioButtonGroup,
  CheckBox
} from '../../../components'
import {
  Container,
  TitleBig,
  CreateContainer,
  StepsWrapper,
  StepContainer,
  InfoText,
  BodyContainer,
  TitleWrapper,
  CheckboxContainer,
  CheckboxWrapper,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { objStorageService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import { useTheme } from 'styled-components'
import { useAuth } from '../../../context/user.context'
import JSONInput from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';


const CreateBucket:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const {user} = useAuth()
  const theme:any = useTheme()
  const [initialValues, setInitialValues ] = useState<any>({
    description:'',
  })
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const [statement, setStatement] = useState<any>('1')

  const onSubmit = (values:any) => {
    setLoading(true)
    objStorageService.createBucket({
      "name": values.name,
      "access": statement
  })
    .then((res:any) => {
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        navigate(-1)
      },1000)
    })
    .catch((err) => {
      if(err.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: err.response?.data?.detail
        })
      } else if(err?.response?.data) {
        setErrors(err.response.data)
      }
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };


  console.log('errors', errors);
  console.log('values', values);
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <CreateContainer>
      <TitleWrapper>
        <BackArrow
          onClick={() => navigate('/object-storage')}
          title={t("BACK_TO_OBJECT_STORAGE_LIST")}
        />
        <TitleBig>{t("CREATE_A_NEW_BUCKET")}</TitleBig>
      </TitleWrapper>
        <BodyContainer>
          <Container>
        <StepsWrapper>
          <StepContainer style={{maxWidth:'545px'}}>
            <Typography variant='h2'>
              1.{t("ENTER_A_NAME")}
            </Typography>
            <Input
              fieldName='name'
              title={''}
              placeholder=''
              {...formikProps}
            />
          </StepContainer>
          <StepContainer>
            <Typography variant='h2'>
              2.{t("ADD_STATEMENT")}
            </Typography>
            <CheckboxWrapper>
              <CheckboxContainer onClick={() => setStatement('1')} active={statement === '1'}>
                <CheckBox
                  title={t("POLICY_GENERATOR")}
                  value={statement === '1'}
                  onChange={() => setStatement('1')}
                />
                <InfoText>
                  {t("POLICY_GENERATOR_INFO")}
                </InfoText>
              </CheckboxContainer>
              <CheckboxContainer onClick={() => setStatement('2')} active={statement === '2'}>
                <CheckBox
                  title={t("JSON_EDITOR")}
                  value={statement === '2'}
                  onChange={() => setStatement('2')}
                />
                <InfoText>
                  {t("JSON_EDITOR_INFO")}
                </InfoText>
              </CheckboxContainer>
            </CheckboxWrapper>
          </StepContainer>
          <StepContainer>
            <InfoText>
              {t("JSON_EDITOR_DESCRIPTION")}
            </InfoText>
            <JSONInput
              viewOnly={statement === '1'}
              id='a_unique_id'
              // placeholder = { sampleObject }
              // colors= { darktheme }
              locale= { locale }
              height= '550px'
              width='545px'
            />
          </StepContainer>
        </StepsWrapper>
        <div style={{display:'flex', marginTop:'40px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={`+ ${t("CREATE")}`}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/floating-ips")}
            title={t("CANCEL")}
          />
        </div>
        </Container>
      </BodyContainer>
    </CreateContainer>
  )
}

export default CreateBucket