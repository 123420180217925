import React, {FC, useState, useEffect} from "react";
import {
  Container,
  TitleContainer,
  TitleWrapper,
  InfoText,
  LinkWrapper,
  Link,
} from './styles'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { Button, Table, TableSkeleton, TopProgress, Typography } from "../../../components";
import { objStorageService } from "../../../api";
import { timeConverterMMDDYYHHMM } from "../../../utils/dates";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../utils/display-price";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";
import { handleStatusName } from "../../../utils/handle-status-name";
import { useNavigate } from "react-router";
import LinkIc from './link.svg'
const BucketPoliciesPage:FC = () => {
  const theme:any = useTheme()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [paginationData, setPaginationData] = useState<any>()
  const [invoices, setInvoices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const [t] = useTranslation()
  const getInvoices = () => {
    setLoading(true)
    // objStorageService.policies(
    //   {
    //     page:page || 1,
    //     page_size:pageSize || 10,
    //     ordering:ordering || '-issue_date',
    //   }
    // )
    // .then((res) => {
    //   setInvoices(res.data.objects.map((invoice:any) => {
    //     return {
    //       ...invoice,
    //       issue_date: timeConverterMMDDYYHHMM(invoice.issue_date),
    //       navigate:invoice.id.toString(),
    //       // status:<StatusText status={invoice.status}>{handleStatusName(invoice.status, t)}</StatusText>,
    //       total:formatCurrency(invoice.total, invoice.currency),
    //     }
    //   }))
    //   setPaginationData(res.data)
    //   setPageLoading(false)
    //   setLoading(false)
    // })
    // .catch((e) => {
    //   console.log('error', e);
    // })
  }
  useEffect(() => {
    getInvoices()
    const interval = setInterval(() => {
      getInvoices()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const ColumnsInvoices = [
    {
      title:'Name',
      key:'name'
    },
    {
      title:t("VISIBILITY"),
      key:'access',
    },
    {
      title:t("POLICY"),
      key:'policies1'
    },
    {
      title:t("OBJECTS"),
      key:'num_objects'
    },
    {
      title:t("SIZE"),
      key:'usage'
    },
    {
      title:'',
      key:'props'
    }
  ]
  if(pageLoading) return (
    <Container>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
        rows={10}
        columns={5}
        // columnsData={Columns}
      />
    </Container>
  )
  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Typography variant='h1'>
            {t("BUCKETS_TITLE")}
          </Typography>
          <InfoText>
            {t("BUCKETS_SUBTITLE")}
          </InfoText>
          <LinkWrapper>
            <Link>{t("BUCKETS_LINK")}</Link>    
            <img src={LinkIc} alt='LinkIc'/>      
          </LinkWrapper>
        </TitleWrapper>
        <Button
          variant="primary"
          size="display"
          customStyles={{whiteSpace:'nowrap'}}
          onClick={() => navigate('/object-storage/create')}
          title={`+ ${t("CREATE")}`}
          // loading={loading}
          // loadingComplete={loadingComplete}
        />
      </TitleContainer>
      {invoices?.length > 0 ?
        <Table
          columns={ColumnsInvoices}
          rows={invoices}
          // hideOther
          // hideCheckbox
          paginationData={paginationData}
        />
      :
        <Typography variant="body">
          {t("NO_BUCKETS")}
        </Typography>
      }
    </Container>
  )
}

export default BucketPoliciesPage

