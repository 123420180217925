import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const objStorageService = {
  getBuckets:(queries:QueriesApi) => ApiClient.get(`object-storage/storage/`),
  getBucketFiles:(id:string, path?:string) => ApiClient.get(`object-storage/storage/${id}/path/${path ? path : ''}`),
  uploadFile:(id:any, data:any) => ApiClient.post(`object-storage/storage/${id}/files/`, data,{
    headers: {
      'Content-Type':'multipart/form-data'
    }
  }),
  getRules:(id:string) => ApiClient.get(`object-storage/storage/${id}/lifecycle/`),
  createRule:(id:string, data:any) => ApiClient.put(`object-storage/storage/${id}/lifecycle/`, data),
  addFolder:(id:string, data:any) => ApiClient.post(`object-storage/storage/${id}/path/`, data),
  updateBucket:(id:string, data:any) => ApiClient.put(`object-storage/storage/${id}/`,data),
  getBucketById:(id:string) => ApiClient.get(`object-storage/storage/${id}/`),
  createBucket:(data:any) => ApiClient.post(`object-storage/storage/`,data),
  deleteBucket:(id:string) => ApiClient.delete(`object-storage/storage/${id}/`),
}

export default objStorageService