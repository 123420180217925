import React, { FC, useEffect, useRef, useState } from "react";
import {
  Container,
  Header,
  TitleWrapper,
  ButtonsWrapper,
  Subtitle,
  Body
} from './styles'
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Link, ModalVariables, NotificationBlock, Table, TableSkeleton, Tabs, TopProgress, Typography } from "../../../../../components";
import { objStorageService } from "../../../../../api";
import IcUpload from './import.svg'
import Skeleton from "react-loading-skeleton";
import { useTheme } from "styled-components";
import { useUi } from "../../../../../context/ui.context";
import IcFolder from './folder-ic.svg'
import IcFile from './document-ic.svg'
import moment from 'moment'
import { formatFileSizeRounded } from "../../../../../utils/sizes";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { icons } from "../../../../../icons";
interface FilesPage {
  files?:any
}

const Component:FC<FilesPage> = ({}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const inputRef:any = useRef()
  const {viewAlert} = useUi()
  const [queryPath, setQueryPath] = useQueryParam('path', StringParam);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
  const [files, setFiles] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [path, setPath] = useState<any>('')
  const {bucketId} = useParams()
  const goUp = () => {
    // Убираем последний уровень директории
    const newPath = path.split('/').slice(0, -2).join('/') + '/';
    setPath(newPath);
    setQueryPath(newPath)
  };
  useEffect(() => {
    if(queryPath){
      console.log('queryPath', queryPath);
      setPath(queryPath)
    }
  },[queryPath])
  console.log('path', path);
  const getFiles = () => {
    //@ts-ignore
    objStorageService.getBucketFiles(bucketId, path||queryPath)
    .then((res) => {
      setPageLoading(false)
      setFiles([
        ...(path && path !== '/'
          ? [
              {
                name: (
                  <div onClick={goUp} style={{ display: 'flex', gap: '6px', alignItems: 'center', cursor: 'pointer' }}>
                    <img src={IcFolder} alt="IcFolder" style={{ width: '16px' }} />
                    <Typography variant="body">...</Typography>
                  </div>
                ),
                hideProps:true
              },
            ]
          : []),
        ...res.data.folders.map((folder:any) => {
          return {
            name: (
              <div onClick={() => {
                setQueryPath(folder.Prefix)
                setPath(folder.Prefix)}} style={{display:'flex', gap:'6px', alignItems:"center", cursor:'pointer'}}>
                <img src={IcFolder} alt="IcFolder" style={{width:"16px"}} />
                <Typography variant="body">{folder.Prefix.replace(res.data.prefix, '').replaceAll("/",'')}</Typography>
              </div>
            ),
            hideProps:true

          }
        }),
        ...res.data.files.map((file:any) => {
          return {
            name: (
              <div style={{display:'flex', gap:'6px', alignItems:"center", whiteSpace:"nowrap"}}>
                <img src={IcFile} alt="IcFile" style={{width:"16px"}} />
                <Typography variant="body">{file.Key.replace(res.data.prefix, '')}</Typography>
              </div>
            ),
            last_modified: moment(file.LastModified).fromNow(),
            size:formatFileSizeRounded(file.Size),
            storage_class:file.StorageClass,
          }
        })
      ])
    })
    .catch((e) => {
      console.log('error', e);
    })
  }
  useEffect(() => {
    getFiles()
    const interval = setInterval(() => {
      getFiles()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[path])
  const theme:any = useTheme()
  if(pageLoading) return (
    <Body>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
        rows={10}
        columns={5}
        // columnsData={Columns}
      />
    </Body>
  )
  const ColumnsFiles = [
    {
      title:'Name',
      key:'name'
    },
    {
      title:t("SIZE"),
      key:'size',
    },
    {
      title:t("LAST_MODIFIED"),
      key:'last_modified'
    },
    {
      title:t("STORAGE_CLASS"),
      key:'storage_class'
    },
    {
      title:'',
      key:'download'
    },
    {
      title:'',
      key:'props'
    }
  ]
  const props = [
    {
      title:t("EDIT_NAME"),
      id:'3',
      icon:icons.Edit,
      onClick: (port:any) => {
        navigate(`${port.id}/edit`)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(file:any) => {
        openModal('delete')
        setAlertTitle(t("PORT"))
        setAlertSubTitle([file])
        // setSelectedPort(port)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const handleUpload = async (e:any) => {
    const file = await e.target.files[0]
    if (file && bucketId) {
      await setLoadingUpload(true)
      // Создаем FormData и добавляем файл и дополнительные данные
      const formData = new FormData();
      formData.append("name", file.name);
      if(path){
        formData.append("path", path.slice(0, -1));
      }
      formData.append("file", file);

      try {
        // Отправка данных на сервер через API
        //@ts-ignore
        const response = await objStorageService.uploadFile(bucketId, formData);
        console.log('res',response.data);
        await setLoadingUpload(false)
      } catch (error) {
        console.error("Произошла ошибка:", error);
      }
    }
    e?.target?.clearData()
  }
  const handleClick = () => {
    inputRef.current?.click();
  };
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setModalType('')
    setActiveAnimation(false)
  }
  const closeModal = (props?:any) => {
    if (modalType === 'add-folder'){
      setLoading(true)
      //@ts-ignore
      objStorageService.addFolder(bucketId, {name:`${path}${props?.name}`})
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          setViewModal(false)
          setActiveAnimation(false)
          setModalType('')
        },1000)
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
      setModalType('')
    }
  }

  // if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <Header>
        <TitleWrapper>
          <Subtitle>{t("FILES_PAGE_DESCRIPTION")}</Subtitle>
          <Link linkText={t("WHAT_AN_OBJECT")} link=""/>
        </TitleWrapper>
        <ButtonsWrapper>
          <Button
            variant="stroke"
            size="display"
            customStyles={{whiteSpace:'nowrap'}}
            onClick={() => openModal('add-folder')}
            title={`+ ${t("ADD_FOLDER")}`}
            // loading={loading}
            // loadingComplete={loadingComplete}
          />
          <input ref={inputRef} style={{display:'none'}} type='file' id="file-input" onChange={(e) => handleUpload(e)} />
          <Button
            variant="primary"
            size="display"
            customStyles={{whiteSpace:'nowrap'}}
            onClick={handleClick}
            title={`${t("UPLOAD")}`}
            icon={IcUpload}
            loading={loadingUpload}
            // loadingComplete={loadingComplete}
          />
        </ButtonsWrapper>
      </Header>
      <Body>
        {files?.length > 0 ?
          <Table
            columns={ColumnsFiles}
            rows={files}
            hideOther
            hideCheckbox
            settings={props}
            // paginationData={paginationData}
          />
        :
          <Typography variant="body">
            {t("NO_FILES_YET")}
          </Typography>
        }
      </Body>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        // loading={loading}
        // loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
      />
    </Container>
  )
}

export default Component