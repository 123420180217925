import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BackArrow,
  Input,
  Select,
  Button,
  TopProgress,
  LoaderSpinner,
  Typography,
  RadioButtonGroup,
  CheckBox,
  Toggle,
  Counter,
} from "../../../components";
import {
  Container,
  TitleBig,
  CreateContainer,
  StepsWrapper,
  StepContainer,
  InfoText,
  BodyContainer,
  TitleWrapper,
  CheckboxContainer,
  CheckboxWrapper,
  DopBlock,
} from "./styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { objStorageService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useTheme } from "styled-components";
import { useAuth } from "../../../context/user.context";
import { useSpring, animated } from '@react-spring/web';
import styled from 'styled-components';

const HidedBlock = styled(animated.div)`
  overflow: hidden;

`;

const CreateLifeCycleRule: FC = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { viewAlert } = useUi();
  const { user } = useAuth();
  const theme: any = useTheme();
  const [initialValues, setInitialValues] = useState<any>({
    description: "",
  });
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [scope, setScope] = useState<string>("1");

  const [transitionVersion, setTransitionVersion] = useState<boolean>(false);
  const [expireVersion, setExpireVersion] = useState<boolean>(false);
  const [expireUploads, setUploads] = useState<boolean>(false);


  const transitionVersionRef:any = React.useRef();
  const expireVersionRef:any = React.useRef();
  const expireUploadsRef:any = React.useRef();
  const scopeRef:any = React.useRef();

  const transitionVersionStyles = useSpring({
    from: { height: 0 },
    to: {
      height: transitionVersion ? transitionVersionRef.current.scrollHeight : 0,
    },
  });
  const expireVersionStyles = useSpring({
    from: { height: 0 },
    to: {
      height: expireVersion ? expireVersionRef.current.scrollHeight : 0,
    },
  });
  const expireUploadStyles = useSpring({
    from: { height: 0 },
    to: {
      height: expireUploads ? expireUploadsRef.current.scrollHeight : 0,
    },
  });
  const scopeStyles = useSpring({
    from: { height: 0 },
    to: {
      height: scope === '2' ? scopeRef.current.scrollHeight : 0,
    },
  });

  const onSubmit = (values: any) => {
    setLoading(true);
    objStorageService
      .createBucket({
        name: values.name,
        // "access": statement
      })
      .then((res: any) => {
        setLoadingComplete(true);
        setTimeout(() => {
          setLoading(false);
          setLoadingComplete(false);
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        if (err.response?.data?.detail) {
          viewAlert({
            severity: "error",
            message: err.response?.data?.detail,
          });
        } else if (err?.response?.data) {
          setErrors(err.response.data);
        }
        setLoading(false);
      });
  };
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  console.log("errors", errors);
  console.log("values", values);
  if (pageLoading) return <TopProgress loading={pageLoading} />;
  return (
    <CreateContainer>
      <TitleWrapper>
        <BackArrow
          onClick={() => navigate("/object-storage")}
          title={t("BACK_TO_OBJECT_STORAGE_LIST")}
        />
        <TitleBig>{t("NEW_LIFECYCLE_RULE")}</TitleBig>
      </TitleWrapper>
      <BodyContainer>
          <StepsWrapper>
            <StepContainer style={{ maxWidth: "545px" }}>
              <Typography variant="h2">1.{t("ENTER_A_NAME")}</Typography>
              <Input
                fieldName="name"
                title={t("RULE_NAME")}
                placeholder=""
                {...formikProps}
              />
              <InfoText>{t("LIFECYCLE_NAME_DESCRIPTION")}</InfoText>
            </StepContainer>
            <StepContainer>
              <Typography variant="h2">2.{t("SELECT_SCOPE")}</Typography>
              <InfoText>{t("SELECT_SCOPE_DESCRIPTION")}</InfoText>
              <CheckboxContainer
                onClick={() => setScope("1")}
                active={scope === "1"}
              >
                <CheckBox
                  title={t("APPLY_ALL_PROJECTS")}
                  value={scope === "1"}
                  onChange={() => setScope("1")}
                />
              </CheckboxContainer>
              <CheckboxContainer
                onClick={() => setScope("2")}
                active={scope === "2"}
              >
                <CheckBox
                  title={t("LIMIT_THE_SCOPE_OF_THE_RULES")}
                  value={scope === "2"}
                  onChange={() => setScope("2")}
                />
                <InfoText style={{ marginLeft: "22px" }}>
                  {t("LIMIT_THE_SCOPE_OF_THE_RULES_DESCRIPTION")}
                </InfoText>
                <HidedBlock ref={scopeRef} style={scopeStyles}>
                  <DopBlock style={{ marginLeft: "22px" }}>
                    <Counter value={1} onChange={() => {}}/>
                  </DopBlock>
                </HidedBlock>
              </CheckboxContainer>
            </StepContainer>
            <StepContainer>
              <Typography variant="h2">3.{t("RULE_CONFIGURATION")}</Typography>
              <InfoText>{t("RULE_CONFIGURATION_DESCRIPTION")}</InfoText>
              <CheckboxContainer
                // onClick={() => setTransitionVersion(!transitionVersion)}
                active={transitionVersion}
              >
                <Toggle
                  title={t("TRANSITION_CURRENT_VERSION")}
                  value={transitionVersion}
                  onChange={() => setTransitionVersion(!transitionVersion)}
                />
                <InfoText style={{ marginLeft: "60px" }}>
                  {t("TRANSITION_CURRENT_VERSION_DESCRIPTION")}
                </InfoText>
                <HidedBlock ref={transitionVersionRef} style={transitionVersionStyles}>
                  <DopBlock>
                    <Select
                      data={[]}
                      title=''
                      selectedValue={''}
                      onChange={() => {}}
                    />
                    <Counter value={1} onChange={() => {}}/>
                  </DopBlock>
                </HidedBlock>
              </CheckboxContainer>
              <CheckboxContainer
                // onClick={() => setExpireVersion(!expireVersion)}
                active={expireVersion}
              >
                <Toggle
                  title={t("EXPIRE_CURRENT_VERSIONS")}
                  value={expireVersion}
                  onChange={() => setExpireVersion(!expireVersion)}
                />
                <InfoText style={{ marginLeft: "60px" }}>
                  {t("EXPIRE_CURRENT_VERSIONS_DESCRIPTION")}
                </InfoText>
                <HidedBlock ref={expireVersionRef} style={expireVersionStyles}>
                  <DopBlock>
                    <Counter value={1} onChange={() => {}}/>
                  </DopBlock>
                </HidedBlock>
              </CheckboxContainer>
              <CheckboxContainer
                // onClick={() => setUploads(!expireUploads)}
                active={expireUploads}
              >
                <Toggle
                  title={t("EXPIRE_INCOMPLETE_MULTIPART_UPLOADS")}
                  value={expireUploads}
                  onChange={() => setUploads(!expireUploads)}
                />
                <InfoText style={{ marginLeft: "60px" }}>
                  {t("EXPIRE_INCOMPLETE_MULTIPART_UPLOADS_DESCRIPTION")}
                </InfoText>
                <HidedBlock ref={expireUploadsRef} style={expireUploadStyles}>
                  <DopBlock>
                    <Counter value={1} onChange={() => {}}/>
                  </DopBlock>
                </HidedBlock>
              </CheckboxContainer>
            </StepContainer>
          </StepsWrapper>
          <div style={{ display: "flex", marginTop: "40px" }}>
            <Button
              variant="primary"
              size="display"
              customStyles={{ marginRight: "10px" }}
              onClick={handleSubmit}
              title={`+ ${t("CREATE")}`}
              loading={loading}
              loadingComplete={loadingComplete}
            />
            <Button
              variant="stroke"
              size="display"
              // customStyles={{width: '81px'}}
              onClick={() => navigate("/floating-ips")}
              title={t("CANCEL")}
            />
          </div>
      </BodyContainer>
    </CreateContainer>
  );
};

export default CreateLifeCycleRule;
