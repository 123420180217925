import React, {FC, useState, useEffect} from "react";
import {
  Container,
  TitleBig,
  NavWrapper,
  AlertsWrapper,
} from './styles'
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { NotificationBlock, Tabs, TopProgress } from "../../../components";
import { objStorageService } from "../../../api";
import {
  FilesPage,
  SettingsPage,
  LifecycleRulesPage,
} from './components'

const BucketPage:FC = () => {
  const [t] = useTranslation()
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("FILES"),
      slug:'files',
      id:'1'
    },
    {
      title:t("BUCKET_SETTINGS"),
      slug:'settings',
      id:'2'
    },
    {
      title:t("LIFECYCLE_RULES"),
      slug:'lifecycle-rules',
      id:'3'
    },
  ])

  const [activeTab, setActiveTab] = useState<string>()
  const [bucket, setBucket] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)

  const {bucketId} = useParams()
  const getData = async () => {
    // Fetch bucket data here
    try{
      //@ts-ignore
      const resBucket = await objStorageService.getBucketById(bucketId)
      // setFiles(resBucket.data)
      setBucket(resBucket.data)
      setPageLoading(false)
    } catch (err:any) {
      setPageLoading(false)
      console.log('err',err);
    }
  }
  console.log('bucket',bucket);
  useEffect(() => {
    getData()
    const interval = setInterval(() => {
      getData()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TitleBig>{bucket.name}</TitleBig>
      <AlertsWrapper>
        <NotificationBlock 
          severity="info"
          title={t("BUCKET_PAGE_ALERT1")}
          message={t("BUCKET_PAGE_ALERT1_SUB")}
          link=""
          linkText={`${t("BUCKET_PAGE_ALERT_LINK")}`}
        />
      </AlertsWrapper>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="files" />} /> 
        <Route path='/files' element={<FilesPage />}/>
        <Route path='/settings' element={<SettingsPage bucket={bucket} />}/>
        <Route path='/lifecycle-rules' element={<LifecycleRulesPage />}/>
      </Routes>
    </Container>
  )
}

export default BucketPage

